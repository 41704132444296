import { createContext, useContext, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useAuth } from './auth';

import Logger from '../shared/logger';
import ListsService from '../shared/list-service';
import { getHomeGridMode } from '../shared/cookies';
import { PRICES_RANGES_FILTER, SUPERADMIN_LEVEL } from '../shared/constants';

import useInterval from '../hooks/useInterval';
import useBigBrother from '../hooks/useBigBrother';
import { useMonitor } from './monitor';

const logger = new Logger('ContextRoomsOnlineList');

const RoomsOnlineContext = createContext({});

export const RoomsOnlineProvider = ({ children }) => {
  const { level, idStudio: userStudio } = useAuth();
  const { setMonitorFloating } = useMonitor();

  const [loadingPerformersOnline, setLoadingPerformersOnline] = useState(true);
  const [listPerformersOnline, setListPerformersOnline] = useState([]);
  const [originalListPerformersOnline, setOriginalListPerformersOnline] =
    useState([]);
  const [tagFiltered, setTagFiltered] = useState('');
  const [studioFiltered, setStudioFiltered] = useState(-1);
  const [managerFiltered, setManagerFiltered] = useState(-1);
  const [roomTypeFiltered, setRoomTypeFiltered] = useState(-1);
  const [roomModeFiltered, setRoomModeFiltered] = useState(-1);
  const [pricesRangeSelected, setPricesRangeSelected] = useState(-1);
  const [deviceFiltered, setDeviceFiltered] = useState(-1);
  const [lastUpdate, setLastUpdate] = useState('-');
  const [sortMode, setSortMode] = useState('busy');
  const [currentViewMode, setCurrentViewMode] = useState(getHomeGridMode());

  const getPerformersOnline = () => {
    setLoadingPerformersOnline(true);
    ListsService.getPerformersOnline()
      .then((onlineList) => {
        let today = new Date();
        setListPerformersOnline(onlineList);
        setOriginalListPerformersOnline(onlineList);
        setLastUpdate(
          today.getHours().toString().padStart(2, '0') +
            ':' +
            today.getMinutes().toString().padStart(2, '0') +
            ':' +
            today.getSeconds().toString().padStart(2, '0')
        );
      })
      .catch((err) => {
        logger.error({ err });
      })
      .finally(() => {
        setLoadingPerformersOnline(false);
      });
  };

  const isPriceInRange = (price) => {
    //get range data from constant
    let rangeData = PRICES_RANGES_FILTER.find(
      (range) => range.value === pricesRangeSelected
    );

    if (!rangeData) return false;

    if (price >= rangeData.min && price <= rangeData.max) {
      return true;
    } else {
      return false;
    }
  };

  const filterList = () => {
    if (
      tagFiltered === '' &&
      studioFiltered === -1 &&
      roomModeFiltered === -1 &&
      roomTypeFiltered === -1 &&
      deviceFiltered === -1 &&
      pricesRangeSelected === -1
    ) {
      return originalListPerformersOnline;
    }

    let listToFilter = originalListPerformersOnline;

    return listToFilter.filter((performer) => {
      let _roomMode = performer.paused === 1 ? 'paused' : performer.roomMode;

      return (
        performer.nick.toLowerCase().includes(tagFiltered.toLowerCase()) &&
        (parseInt(performer.study_id) === studioFiltered ||
          studioFiltered === -1) &&
        (parseInt(performer.manager_id) === managerFiltered ||
          managerFiltered === -1) &&
        (parseInt(performer.room_type) === roomTypeFiltered ||
          roomTypeFiltered === -1) &&
        (parseInt(performer.device) === parseInt(deviceFiltered) ||
          parseInt(deviceFiltered) === -1) &&
        (_roomMode === roomModeFiltered || parseInt(roomModeFiltered) === -1) &&
        (isPriceInRange(performer.credits_group) ||
          parseInt(pricesRangeSelected) === -1) &&
        (isPriceInRange(performer.credits_group) ||
          parseInt(pricesRangeSelected) === -1) &&
        (isPriceInRange(performer.credits_group) ||
          parseInt(pricesRangeSelected) === -1)
      );
    });
  };

  const sortList = (listToSort) => {
    if (sortMode === 'nick') {
      return listToSort.slice(0).sort((a, b) => {
        if (a.nick.toLowerCase() < b.nick.toLowerCase()) {
          return -1;
        } else {
          return 1;
        }
      });
    }

    if (sortMode === 'new') {
      return listToSort.slice(0).sort((a, b) => {
        if (a.new > b.new) {
          return -1;
        } else {
          return 1;
        }
      });
    }

    if (sortMode === 'busy') {
      return listToSort.slice(0).sort((a, b) => {
        if (parseInt(a.weight) > parseInt(b.weight)) {
          return -1;
        } else {
          return 1;
        }
      });
    }
  };

  const sortAndFilterPerformersOnline = () => {
    setListPerformersOnline(sortList(filterList()));
  };

  const resetFilters = () => {
    setTagFiltered('');
    setStudioFiltered(level !== SUPERADMIN_LEVEL ? userStudio : -1);
    setManagerFiltered(-1);
    setRoomTypeFiltered(-1);
    setRoomModeFiltered(-1);
    setDeviceFiltered(-1);
    setRoomTypeFiltered(-1);
    setPricesRangeSelected(-1);
  };

  const isFiltered = () => {
    if (
      tagFiltered === '' &&
      studioFiltered === -1 &&
      roomModeFiltered === -1 &&
      roomTypeFiltered === -1 &&
      deviceFiltered === -1 &&
      pricesRangeSelected === -1
    )
      return false;

    return true;
  };

  useEffect(() => {
    getPerformersOnline();

    return () => {
      setMonitorFloating(true);
    };
  }, []);

  useEffect(() => {
    sortAndFilterPerformersOnline();
  }, [
    tagFiltered,
    studioFiltered,
    managerFiltered,
    roomModeFiltered,
    roomTypeFiltered,
    deviceFiltered,
    originalListPerformersOnline,
    pricesRangeSelected,
    sortMode,
  ]);

  useEffect(() => {
    if (level === -1) return;
    if (!userStudio) return;
    if (level !== SUPERADMIN_LEVEL) setStudioFiltered(userStudio);
  }, [level, userStudio]);

  useInterval(() => getPerformersOnline(), 30000);

  const refreshUsersOnlineCounters = (totalUsers, roomId, payingMode) => {
    let _listPerformersOnline = listPerformersOnline;
    let performerIndex = _listPerformersOnline.findIndex(
      (room) => room.id === roomId
    );

    if (payingMode === 'payingusers' && totalUsers > 0) {
      _listPerformersOnline[performerIndex] = {
        ..._listPerformersOnline[performerIndex],
        users: parseInt(totalUsers),
        freeusers: 0,
        weight: 20 + parseInt(totalUsers),
      };
    } else {
      _listPerformersOnline[performerIndex] = {
        ..._listPerformersOnline[performerIndex],
        freeusers: parseInt(totalUsers),
        users: 0,
        roomMode: 'freechat',
        weight: parseInt(totalUsers) > 0 ? 10 + parseInt(totalUsers) : 1,
      };
    }

    setListPerformersOnline(sortList(_listPerformersOnline));
  };

  return (
    <RoomsOnlineContext.Provider
      value={{
        getPerformersOnline,
        isFiltered,
        resetFilters,
        sortAndFilterPerformersOnline,
        loadingPerformersOnline,
        lastUpdate,
        currentViewMode,
        listPerformersOnline,
        originalListPerformersOnline,
        setCurrentViewMode,
        refreshUsersOnlineCounters,
        setStudioFiltered,
        setManagerFiltered,
        setRoomModeFiltered,
        setTagFiltered,
        setDeviceFiltered,
        setRoomTypeFiltered,
        setPricesRangeSelected,
        pricesRangeSelected,
        studioFiltered,
        managerFiltered,
        roomModeFiltered,
        roomTypeFiltered,
        deviceFiltered,
        setSortMode,
        sortMode,
      }}
    >
      {children}
    </RoomsOnlineContext.Provider>
  );
};

export const useRoomsOnlineContext = () => useContext(RoomsOnlineContext);
